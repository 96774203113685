import { render, staticRenderFns } from "./exportLoader.vue?vue&type=template&id=c3c490ac&"
import script from "./exportLoader.vue?vue&type=script&lang=js&"
export * from "./exportLoader.vue?vue&type=script&lang=js&"
import style0 from "./exportLoader.vue?vue&type=style&index=0&id=c3c490ac&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports