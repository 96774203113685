<template>
  <div class="promo-export">
    <transition
      enter-from-class="opacity-0"
      leave-to-class="opacity-0"
      enter-active-class="export-transition"
      leave-active-class="export-transition"
      mode="out-in"
    >
      <component :is="currentComponent" :downloadLink="exportLink" @selectType="handleSelectType" @closeModal="closeModal" />
    </transition>
  </div>
</template>
<script>

import apiClient from '@/services/axios'
import SelectExportType from '@/views/apps/promo-images/modals/exportPromo/components/selectExportType.vue'
import ExportLoader from '@/views/apps/promo-images/modals/exportPromo/components/exportLoader.vue'
import DownloadExport from '@/views/apps/promo-images/modals/exportPromo/components/downloadExport.vue'

export default {
  name: 'exportPromoModal',
  props: [],
  components: { SelectExportType, ExportLoader, DownloadExport },
  data: () => ({
    loading: false,
    currentComponent: 'SelectExportType',
    selectedExportType: '',
    exportLink: '',
  }),
  computed: {
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
    async handleSelectType(exportType) {
      this.selectedExportType = exportType
      this.currentComponent = 'ExportLoader'
      try {
        const response = await apiClient.post(`/admin/promo/export/${this.selectedExportType}`)
        if (response.data.success) {
          this.checkExportStatus()
        }
      } catch (e) {
        const errorMessage =
          e.response.data?.error?.message ||
          'Something went wrong while exporting promo images'
        this.$notification.error({
          message: errorMessage,
        })
      }
    },
    async checkExportStatus() {
      try {
        const response = await apiClient.get(`/admin/promo/export/${this.selectedExportType}/status`)
        if (response.data.data.message === 'File generation is success') {
          this.exportLink = response.data.data.link
          this.currentComponent = 'DownloadExport'
        } else {
          console.log('Waiting for file generation to complete...')
          setTimeout(() => {
            this.checkExportStatus()
          }, 5000)
        }
      } catch (e) {
        const errorMessage =
        e.response.data?.error?.message ||
        'Something went wrong while exporting promo images'
        this.$notification.error({
          message: errorMessage,
        })
      }
    },
  },
  async created() {
  },
}
</script>

<style lang="scss">
.promo-export {
  &__radio {
    display: block !important;
    height: 30px;
    line-height: 30px;
  }
  .opacity-0 {
    opacity: 0;
  }
  .export-transition {
    transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
  }
}
</style>
