<template>
    <div class="loader-container">
      <LottieAnimation
      style="height: 200px;"
      ref="anim"
      :animationData="require('../jsonAnim/spaceshipAnim.json')"
      :loop="true"
      :autoPlay="true"
      />
      <span class="downloadText">Download link is being generated</span>
      <img class="loader-line" src="/resources/images/loader.gif" />
    </div>
</template>
<script>

import LottieAnimation from 'lottie-web-vue'

export default {
  name: 'exportLoader',
  props: [],
  components: { LottieAnimation },
  data: () => ({
  }),
  computed: {
  },
  methods: {
    startExport() {
      console.log('lmao')
    },
  },
  async created() {
  },
}
</script>

  <style lang="scss">
  .loader-container {
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    .downloadText {
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 16px;
    }
    .loader-line {
      padding-bottom: 16px;
    }
  }
  </style>
